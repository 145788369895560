<template>
  <div class="text-center  " >
    <button class="btn btn-danger rounded-full my-2 mx-2" @click="$store.state.dialog = true">
      Fine Writer
    </button>

    <v-dialog
      v-model="$store.state.dialog"
      class="text-left"
      transition="dialog-top-transition"
      max-width="600"
    >
      <v-card :loading="finesForm.busy">
        <v-card-title class="text-h5"> New Fine </v-card-title>
        <v-card-text>
          <form
            class="flex-fill text-left"
            ref="finesForm"
            @submit.prevent="createFine"
          >
            <div class>
              <div class="mb-3">
                <span class="d-block text-muted">All fields are required</span>
              </div>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    outlined
                    label="Amount"
                    name="amount"
                    type="number"
                    v-model="finesForm.amount"
                    hide-details
                  />
                  <div class="v-text-field__details">
                    <div class="v-messages theme--light">
                      <div class="v-messages__wrapper">
                        <has-error
                          :form="finesForm"
                          class="v-messages theme--light error--text"
                          field="amount"
                        ></has-error>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    outlined
                    label="Description"
                    name="description"
                    v-model="finesForm.description"
                    hide-details
                  />
                  <div class="v-text-field__details">
                    <div class="v-messages theme--light">
                      <div class="v-messages__wrapper">
                        <has-error
                          :form="finesForm"
                          class="v-messages theme--light error--text"
                          field="description"
                        ></has-error>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>

                <button
                  :disabled="finesForm.busy"
                  type="submit"
                  class="white--text btn rounded-0 btn-primary"
                  >{{ edit ? "Update" : "Fine" }}</button
                >
                <button
                  type="button"
                  class="btn btn-danger text-white rounded-0 mx-2"
                  @click="$store.state.dialog = !$store.state.dialog"
                  >Close</button
                >
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    writer_id: {
      type: String,
      default: "",
    },
    order_id: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState("payment", ["finesForm"]),
  },
  methods: {
    createFine() {
      this.finesForm.order_id = this.order_id;
      this.finesForm.writer_id = this.writer_id;
      this.finesForm
        .post(`${this.$baseUrl}/Payments/Fines`)
        .then((res) => {
          this.$store.state.dialog = false;
          this.$notify({
            title: "Success",
            text: `Fine created successfully.`,
            style: "success",
          });
          this._getFines();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: err.response.data
              ? err.response.data.Message
              : `Error while creating fine`,
            style: "danger",
          });
        });
    },
  },

  mounted() {
    this.finesForm.order_id = this.order_id;
    this.finesForm.writer_id = this.writer_id;
  },
};
</script>