<template>
  <div
    v-if="action"
    name="actionModal"
    class="modal fade text-left"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    ref="statusModal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title font-weight-bold text-capitalize"
            id="exampleModalLabel"
          >
            {{ action.title || "" }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="action.require_message">
            <BaseOverlay v-if="loading"></BaseOverlay>
            <div class="col-12" v-else>
              <div v-if="action.type === 'float'" class="form-group">
                <label for="inputEmail3" class="col-form-label text-left"
                  >Amount</label
                >
                <div>
                  <input
                    type="number"
                    v-model="statusForm.message"
                    class="form-control"
                    id="inputEmail3"
                    placeholder="Amount"
                    required="required"
                  />
                </div>
              </div>

              <div v-else-if="action.type === 'date'" class="form-group">
                <label for="inputEmail3" class="col-form-label text-left"
                  >Select Date</label
                >
                <div>
                  <input
                    type="datetime-local"
                    v-model="statusForm.message"
                    class="form-control"
                    id="inputEmail3"
                    placeholder="Date"
                    required="required"
                  />
                </div>
              </div>

              

              <div v-else-if="action.type === 'select'">
                <div
                  v-if="action.action === 'revision'"
                  class="form-group"
                >
                  <label
                    for="inputEmail3"
                    class=" text-right"
                    >Deadline / Urgency</label
                  >
                  <div>
                    <vue-select
                      label="desc"
                      v-model="statusForm.message"
                      placeholder="Start typing to search"
                      @input="calculatePrice"
                      :options="urgency"
                      :reduce="(urg) => urg.id"
                    ></vue-select>
                  </div>
                </div>
              </div>

              <!-- date, select, string, float -->
              <div v-else class="form-group">
                <label for="inputEmail3" class="col-form-label text-left"
                  >Description</label
                >
                <div>
                  <textarea
                    v-model="statusForm.message"
                    class="form-control"
                    id="inputEmail3"
                    placeholder="Description"
                    required="required"
                  ></textarea>
                </div>
              </div>
              <button
                class="btn btn-primary rounded-0 mt-2"
                :disabled="statusForm.busy"
                @click="changeStatus(action.action)"
              >
                {{ action.title || "submit" }}
              </button>
              <button
                type="button"
                class="btn mt-2 ml-2 text-white btn-danger rounded-0"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>

          <div v-else class="">
            <div>
              <BaseOverlay v-if="loading"></BaseOverlay>
              <div v-else>
                <div class="alert alert-info mb-2">
                  <strong>Confirm</strong>
                  <p>Are you sure you want to perform this action?</p>
                </div>
                <div v-if="action.action == 'assignwriter'" class="text-left">
                  <!-- {{writers}} -->
                  <v-autocomplete
                    class="text-left mb-4"
                    :items="writers"
                    item-text="names"
                    item-value="id"
                    v-model="writer_id"
                    append-icon="mdi-link"
                    label="Select writer"
                    dense
                    outlined
                    hide-details
                  ></v-autocomplete>
                </div>
              </div>

              <div class="float-right">
                <button
                  type="button"
                  class="btn btn-danger rounded-0 white--text mr-2"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary rounded-0 white--text"
                  :disabled="statusForm.busy"
                  @click="changeStatus(action.action)"
                >
                  Yes, {{ action.title || "" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "BaseModal",
  props: {
    action: {
      default: {},
    },
    writers: {
      default: [],
    },
    modalId: {
      type: String,
      default: "exampleModal1",
    },
  },

  computed: {
    ...mapState("order", ["loading", "statusForm", "order"]),
    ...mapGetters("setups", ["urgency"]),
  },

  data: () => ({
    writer_id: "",
    order_id: "",
    
  }),

  methods: {
    ...mapActions("sidebar", ["getSidebarMenu"]),
    ...mapActions("order", ["_changeStatus"]),
    // ...mapActions("order", ["_changeStatus"]),
    changeStatus() {
      this.statusForm.order_id = this.order.id;
      this.statusForm.writer_id = this.writer_id;
      this.statusForm.status = this.action.action;
      console.log("this.statusForm", this.statusForm);
      this._changeStatus(this.statusForm)
        .then((res) => {
          // success
          this.$notify({
            title: "Success",
            text: res.data.Message || "Order status updated",
            style: "success",
          });
          this.getSidebarMenu();
          this.statusForm.reset();
          // close modal
          this.$refs["statusModal"].classList.remove("show");
          this.$refs["statusModal"].classList.add("is-hidden");
          console.log(this.$refs["statusModal"]);
        })
        .catch((err) => {
          console.log("update status error", err);
          this.$notify({
            title: "Error",
            text:
              err.response && err.response.data
                ? err.response.data.Message
                : "Error",
            style: "danger",
          });
        });
    },

    
  },


};
</script>
<style lang="scss" scoped>
.overlay-loading {
  display: flex;
  flex-direction: column;
  position: inherit !important;
  z-index: 1;
  justify-content: center;
  height: inherit !important;
  width: inherit !important;
}
.modal-body {
  min-height: 200px !important;
}
.is-hidden {
  display: none;
}
</style>


