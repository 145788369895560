<template>
    <div>
        <form @submit.prevent="updateOrder">
            <div class="form-group">
                <label for="inputEmail3" class="text-right">Select writer preferences</label>
                <div class="">
                    <div class="btn-group" v-if="writer_levels.length > 0">
                        <button
                            type="button"
                            class="btn col-4" 
                            v-for="(pref, index) in writer_levels" 
                            :key="index + 'wp'"
                            :class="writerLevelForm.writer_level === pref.id ? 'btn-dark' : 'btn-outline-dark'"
                            @click="activateWriterPref(pref)">
                            <div>
                            <h6 class="my-0 text-left">
                                {{ pref.title }}
                                <br>
                                <span>{{ `(${pref.cost})` || "" }}</span>  
                            </h6>
                            <p class="my-0 text-left text-muted col-12" style="white-space: normal;word-wrap: break-word;">{{ pref.desc }}</p>
                            </div>
                        </button>
                    </div>

                    <button @click="doUpdateOrder" class="btn btn-primary mt-2">Update Writer Preferences</button>
                </div>
            </div>
        </form>
    </div>
</template>


<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
export default {
    name: "WriterPreferences",
    computed: {
        ...mapGetters("setups", ["writer_levels"]),
        ...mapState("order", ["writerLevelForm", "price"]),
    },
    methods: {
        ...mapMutations("order", ["updatePages", "_getOrder"]),
        ...mapActions("order", ["updateOrder", "_calculatePrice", "updateWriterPreferences"]),
        doUpdateOrder() {
            console.log(this.writerLevelForm);
            this.updateWriterPreferences(this.writerLevelForm)
                .then((res) => {
                    // success
                    this.$notify({
                        title: "Success",
                        text: res.data.Message || "Order updated",
                        style: "success",
                    });
                    // close modal
                    console.log(res);
                })
                .catch((err) => { 
                    console.log(err)
                    this.$notify({
                        title: "Error",
                        text: err.response.data ? err.response.data.Message : "Error",
                        style: "danger",
                    });
                })

        },
        activateWriterPref(pref) {
            this.writerLevelForm.writer_level = pref.id
            // this._calculatePrice(this.writerLevelForm)
        },
    }
}
</script>