<template>
  <div>
    <BaseLoading style="position: fixed; z-index: 3; top: 50%; right: 50%" v-if="is_loading"></BaseLoading>
    <div class="order-info text-left">
      <div class="row">
        <div class="col-12 col-sm-8 table-responsive">
          <div class="order-details-item my-2 order-details-item-paper-detailes">
            <div class="order-details-header d-flex justify-content-between">
              <div class="titles py-2 ">Order details</div>
            </div>

          </div>
          <table class="table table-borderless table--custom  text-left " style="font-size: 13px !important">
            <tr>
              <td>Topic</td>
              <td colspan="2"> <span v-html="order.title"></span></td>
            </tr>


            <tr>
              <td>Number of Page(s)</td>
              <td> {{ order.pages || "" }}</td>
              <td> <button v-for="(item, index) in additionalPage" :key="index" @click="activeComponent = item"
                  data-toggle="modal" data-target="#exampleModal" class=" btn btn-lg btn-outline-success ">
                  <!-- <img src="/plus-circle-outline.svg" :alt="item.title"/>  -->
                  {{ item.title }} </button> </td>
            </tr>
            <tr>
              <td>Number of Slide(s)</td>
              <td> {{ order.slides || "" }}</td>
              <td>
                <button v-for="(item, index) in additionalSlides" :key="index" @click="activeComponent = item"
                  data-toggle="modal" data-target="#exampleModal" class=" btn btn-lg btn-outline-success ">
                  <!-- <img src="/plus-circle-outline.svg" :alt="item.title"/>  -->
                  {{ item.title }} </button>
              </td>
            </tr>
            <tr>
              <td>Number of Chart(s)</td>
              <td> {{ order.charts || "" }}</td>
              <td>
                <button v-for="(item, index) in additionalCharts" :key="index" @click="activeComponent = item"
                  data-toggle="modal" data-target="#exampleModal" class=" btn btn-lg btn-outline-success ">
                  <!-- <img src="/plus-circle-outline.svg" :alt="item.title"/>  -->
                  {{ item.title }} </button>
              </td>
            </tr>

            <tr>
              <td>Spacing</td>
              <td> {{ order.spacing_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Language Style</td>
              <td> {{ order.language_style_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Type of Paper</td>
              <td> {{ order.document_type_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Subject Area</td>
              <td> {{ order.subject_area_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Academic Level</td>
              <td> {{ order.academic_level_text || "" }}</td>
              <td></td>
            </tr>
            <!-- 
            <tr>
              <td>Urgency</td>
              <td>
                <span v-if="order.deadline" :class="order.past_due ? 'text-danger' : 'text-success'">: {{
                    order.urgency_text_hrs || ""
                }}</span>
              </td>
              <td>
                <button  v-for="(item, index) in additionalDeadline" :key="index" @click="activeComponent = item " data-toggle="modal"
                data-target="#exampleModal" class=" btn btn-lg btn-outline-success " >
                <img src="/plus-circle-outline.svg" :alt="item.title"/> 
                {{item.title}} </button> 
              </td>
            </tr>
 -->


            <tr>
              <td>Deadline</td>
              <td :class="
                order.writer_deadline.past_due
                  ? 'text-danger'
                  : 'text-success'
              " class="font-weight-bold">
                {{ order.writer_deadline.date || "" }}
              </td>
              <td :class="
                order.writer_deadline.past_due
                  ? 'text-danger'
                  : 'text-success'
              " class="font-weight-bold">
                {{ order.writer_deadline.past_due ? "Elapsed" : "" }}
                {{ order.writer_deadline.ellapsed || "" }}
              </td>
            </tr>



            <tr>
              <td>State</td>
              <td>{{ order.state || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Nursing Speciality</td>
              <td>{{ order.speciality || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Paper Format / Style</td>
              <td>{{ order.style_text || "" }}</td>
              <td></td>
            </tr>

            <tr>
              <td>Number of sources</td>
              <td>{{ order.no_sources || "0" }}</td>
              <td>

              </td>
            </tr>
            <tr>
              <td>Writer Level</td>
              <td>{{ order.writer_level_desc || "0" }}</td>
              <td>
                <!-- <button  v-for="(item, index) in additionalPreferences" :key="index" @click="activeComponent = item " data-toggle="modal"
                data-target="#exampleModal" class=" btn btn-lg btn-outline-success " >
                {{item.title}} </button>  -->
              </td>
            </tr>

            <tr>
              <td>Additional Services</td>
              <td>
                <table class="table table-borderless">
                  <tr v-for="(item, index) in order.additional_services_text" :key="index + 'add'">
                    <td>{{ item[0] }}</td>
                    <hr />
                  </tr>
                </table>
              </td>
              <td>
                <!-- <button  v-for="(item, index) in additionalServices" :key="index" @click="activeComponent = item " data-toggle="modal"
                data-target="#exampleModal" class=" btn btn-lg btn-outline-success " >
                {{item.title}} </button>  -->
              </td>
            </tr>


            <tr>
              <td class="font-weight-bold">Writer Amount</td>
              <td class="font-weight-bold">
                {{ order.writer_cost ? `$${order.writer_cost}` : "" }}
              </td>
              <td></td>
            </tr>


          </table>
          <div style="margin-top:30px!important; " class="order-details-item  order-details-item-paper-detailes">
            <div class="order-details-header d-flex justify-content-between">
              <div class="titles py-2 ">Paper details</div>
            </div>
            <div class="order-details-block">
              <div class="order-option-value order-option-instructions"
                data-full-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia <span class='see-less-instructions'>See less</span>"
                data-truncate-text="In ducimus mollitia In ducimus mollitia In ducimus mollitia">
                <pre style="padding: 4px !important">
                   <span v-html="order.description"></span>
                  </pre>
              </div>
            </div>

          </div>
        </div>

        <div class="col-12 col-sm-4">
          <!-- <div class="order-details-item my-2 order-details-item-paper-detailes">
            <div class="order-details-header d-flex justify-content-between">
                <div class="titles py-2 ">Add Services to Your Paper</div>
            </div>                                     
            
          </div> -->
          <!-- <h6 class="font-weight-bold text-uppercase">
            Add Services to Your Paper
          </h6> -->

          <!-- <div class="row">
            <div v-for="(item, index) in additionalItems" :key="index" class="col-6 py-3">
              <p class="font-weight-bold text-center is-additional" @click="activeComponent = item" data-toggle="modal"
                data-target="#exampleModal">
                <img width="62px" :src="item.icon || ''" />
                <br />
                <span class="text-center"> {{ item.title || "" }}</span>
              </p>
            </div>
          </div> -->
        </div>
      </div>

      <!-- Modal -->
      <BaseModal :title="activeComponent.title">
        <component :is="activeComponent.component"></component>
      </BaseModal>
    </div>
  </div>
</template>


<script>
import pages_png from "../../assets/global_assets/images/ordericons/pages.png";
import slides_png from "../../assets/global_assets/images/ordericons/slides.png";
import charts_png from "../../assets/global_assets/images/ordericons/charts.png";
import deadline_png from "../../assets/global_assets/images/ordericons/deadline.png";
import progressive_png from "../../assets/global_assets/images/ordericons/progressive.png";
import preferences_png from "../../assets/global_assets/images/ordericons/preferences.png";

import AdditionalPages from "../order/modals/AdditionalPages.vue";
import AdditionalServices from "../order/modals/AdditionalServices.vue";
import AdditionalCharts from "../order/modals/AdditionalCharts.vue";
import AdditionalSlides from "../order/modals/AdditionalSlides.vue";
import ShortenDeadline from "../order/modals/ShortenDeadline.vue";
import WriterPreferences from "../order/modals/WriterPreferences.vue";
import { mapActions, mapState } from "vuex";
import SilentLogin from "../SilentLogin.vue";
export default {
  props: {
    order: {
      type: Object,
    },
  },
  computed: {
    ...mapState("auth", ["user"])

  },
  components: {
    AdditionalPages,
    AdditionalServices,
    AdditionalCharts,
    AdditionalSlides,
    ShortenDeadline,
    WriterPreferences,
    SilentLogin,
  },
  data() {
    return {
      is_loading: false,
      activeComponent: {},
      additionalPage: [
        {
          title: "Request Additional Pages",
          icon: pages_png,
          component: "AdditionalPages",
        }
      ],
      additionalSlides: [
        {
          title: "Request Powerpoint slides",
          icon: slides_png,
          component: "AdditionalSlides",
        }
      ],
      additionalCharts: [
        {
          title: "Request Additional Charts",
          icon: charts_png,
          component: "AdditionalCharts",
        }
      ],
      additionalDeadline: [

        {
          title: "Extend Deadline",
          icon: deadline_png,
          component: "ShortenDeadline",
        }
      ],
      additionalPreferences: [
        {
          title: "Update Writer Preferences",
          icon: preferences_png,
          component: "WriterPreferences",
        }
      ],
      additionalServices: [
        {
          title: "Additional Services",
          icon: progressive_png,
          component: "AdditionalServices",
        },
      ],

      // additionalItems: [
      //   {
      //     title: "Additional Pages",
      //     icon: pages_png,
      //     component: "AdditionalPages",
      //   },
      //   {
      //     title: "Powerpoint slides",
      //     icon: slides_png,
      //     component: "AdditionalSlides",
      //   },
      //   {
      //     title: "Charts",
      //     icon: charts_png,
      //     component: "AdditionalCharts",
      //   },
      //   {
      //     title: "Shorten Deadline",
      //     icon: deadline_png,
      //     component: "ShortenDeadline",
      //   },
      //   {
      //     title: "Update Writer Preferences",
      //     icon: preferences_png,
      //     component: "WriterPreferences",
      //   },
      //   {
      //     title: "Additional Services",
      //     icon: progressive_png,
      //     component: "AdditionalServices",
      //   },
      // ],

    };
  },

  methods: {
    ...mapActions("order", ["deleteExtras"]),
    deleteExtra(id) {
      this.is_loading = true;
      this.deleteExtras(id)
        .then((res) => {
          console.log(res);
          // this,_getOrder(this.order.id)
          // alert
          this.$notify({
            title: "Success",
            text: "Order extra deleted successiful!",
          });
        })
        .catch((err) => {
          console.log(err);
          // alert
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table td {
  //   padding: 4px 8px !important;
  max-width: 300px;
}

.is-additional {
  cursor: pointer;

  img {
    margin: 4px;
  }

  &:hover {
    img {
      transform: scale(1.05);
      transition: 0.3s all ease-in-out;
    }
  }
}
</style>

<style lang="scss">
.btn.btn-dark.btn-icon {
  color: #fff !important;
}
</style>