<template>
  <div
    class="page-header page-header-light bg-primary shadow is-animated"
    :class="deadline.past_due ? 'bg-danger' : 'bg-primary'"
    v-show="shouldShowStatus"
  >
    <div class="page-header-content header-elements-md-inline text-left">
      <div class="page-title d-flex py-2">
        <h4>
          <span class="font-weight-semibold text-capitalize"
            >{{ deadline.past_due ? "Late" : "Due" }} :</span
          >
          <span>{{ deadline.ellapsed }}</span>
        </h4>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  props: {
    order: {
      default: {},
    },
  },
  computed: {
    ...mapGetters("auth", ["userType", "userTypes"]),
    deadline() {
      let dline = "";
      if (this.userType == this.userTypes.admin) {
        dline = this.order.deadline;
      } else if (this.userType == this.userTypes.editor) {
        dline = this.order.editor_deadline;
      } else {
        dline = this.order.writer_deadline;
      }
      return dline;
    },

    shouldShowStatus() {
      if ("approved completed cancelled".includes(this.order.status_text.toLowerCase())) {
        return false;
      }
      return true;
    },
  },
};
</script>